const Theme = {
    color: {
        // primaryColor: "cadetblue",
        primaryColor: "#187f86",
        secondaryColor: "darkseagreen",
        thirdColor: "#3f51b5",
        manIconColor: "cornflowerblue",
        womanIconColor: "pink",
        hitNumColor: "blue",
    },
    font: {
        fontFamily:
            "'Avenir', 'Helvetica Neue', 'Helvetica', 'Arial', 'Hiragino Sans', 'ヒラギノ角ゴシック', YuGothic, 'Yu Gothic', 'メイリオ', Meiryo, 'ＭSＰゴシック', 'MS PGothic', sans-serif",
    },
}

export default Theme
