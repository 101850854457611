/**
 * データをfetchしjsonデータを返すGeneric型のHook
 * @param url fetchするAPIのURL
 * @param headers headers情報
 * @param data 返却されたjsonデータ
 */

const fetchHandler = async <T>(url: string, headers?: T) => {
    let response
    try {
        if (headers) {
            //POST送信
            response = await fetch(url, headers)
        } else {
            //GET送信
            response = await fetch(url)
        }
        if (response.ok) {
            const responseJson = await response.json()
            return responseJson
        } else {
            console.error(`Error ${response.status} ${response.statusText}`)
        }
    } catch (error) {
        console.error(`ERROR:${error}`)
    }
}

export default fetchHandler
