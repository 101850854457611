import moment from "moment"
import { window } from "ssr-window"

import fetchHander from "./fetchHandler"

const shortid = require("shortid")

//サーバー接続の切り替え
let origin: any
switch (window.location && window.location.host) {
    case "localhost:8000": //開発
        origin = "http://localhost:5002/atoko-hitrate/us-central1/api"
        break
    default:
        //devと本番
        origin = "https://us-central1-atoko-hitrate.cloudfunctions.net/api"
}

type HeadersProps = {
    method: string
    headers: {
        "Content-Type": string
    }
    body?: string
}
let headers: HeadersProps = {
    method: "POST",
    headers: {
        "Content-Type": "application/json; charset=utf-8",
    },
}

export const userRepository = {
    login: async (loginInfo: any) => {
        headers.body = JSON.stringify({
            email: loginInfo.email,
            password: loginInfo.password,
        })
        const data = await fetchHander(`${origin}/login`, headers)
        return JSON.parse(data)
    },
    sessionLogin: async (email: string, password: string) => {
        headers.body = JSON.stringify({
            email: email,
            password: password,
        })
        const data = await fetchHander(`${origin}/sessionLogin`, headers)
        return JSON.parse(data)
    },
    memberDataFetch: async (loginUser: any) => {
        headers.body = JSON.stringify({
            id: loginUser.id,
            team: loginUser.team,
        })
        const data = await fetchHander(`${origin}/memberDataFetch`, headers)
        return JSON.parse(data)
    },
    allMemberDataFetch: async (fetchUser: any, memberData: any) => {
        headers.body = JSON.stringify({
            memberData,
            id: fetchUser.id,
            team: fetchUser.team,
            rec_name: "memberRecords",
        })
        const data = await fetchHander(`${origin}/allMemberDataFetch`, headers)
        return JSON.parse(data)
    },
    monthMemberDataFetch: async (loginUser: any, memberData: any) => {
        let monthYear = new Date()
        let year = String(monthYear.getFullYear())
        let month = String(monthYear.getMonth() + 1)
        headers.body = JSON.stringify({
            id: loginUser.id,
            rec_name: "records",
            memberData,
            team: loginUser.team,
            year,
            month,
        })
        const data = await fetchHander(`${origin}/monthMemberDataFetch`, headers)
        return JSON.parse(data)
    },

    nameChange: async (loginUser: any, newName: string) => {
        headers.body = JSON.stringify({ team: loginUser.team, id: loginUser.id, newName })
        const data = await fetchHander(`${origin}/nameChange`, headers)
        return JSON.parse(data)
    },
}

export const adminRepository = {
    permissionChange: async (loginUser: any, id: string, permission: string) => {
        headers.body = JSON.stringify({ id, team: loginUser.team, permission })
        const data = await fetchHander(`${origin}/permissionChange`, headers)
        return JSON.parse(data)
    },
    statusEmailSend: async (email: string, newStatus: string) => {
        headers.body = JSON.stringify({ email, newStatus })
        const data = await fetchHander(`${origin}/statusEmailSend`, headers)
        return JSON.parse(data)
    },
    typeChange: async (id: string, team: string, type: string) => {
        headers.body = JSON.stringify({ id, team, type })
        const data = await fetchHander(`${origin}/typeChange`, headers)
        return JSON.parse(data)
    },
    typeEmailSend: async (email: string, newType: string) => {
        headers.body = JSON.stringify({ email, newType })
        const data = await fetchHander(`${origin}/typeEmailSend`, headers)
        return JSON.parse(data)
    },
    displayChange: async (id: string, team: string, displayFlag: string) => {
        headers.body = JSON.stringify({ id, team, displayFlag })
        const data = await fetchHander(`${origin}/displayChange`, headers)
        return JSON.parse(data)
    },
}

export const recordsRepository = {
    allRecordsFetch: async (fetchUser: any) => {
        headers.body = JSON.stringify({ id: fetchUser.id, team: fetchUser.team })
        const data = await fetchHander(`${origin}/allRecordsFetch`, headers)
        return JSON.parse(data)
    },
    recordCorrect: async (
        loginUser: any,
        record: any,
        rec_name: any,
        hitArrow: any,
        allArrow: any
    ) => {
        headers.body = JSON.stringify({
            deleteRecord: record,
            team: loginUser.team,
            id: loginUser.id,
            rec_name,
            hit_arrow: hitArrow,
            all_arrow: allArrow,
            date: record.date,
            record_id: record.id,
        })
        const data = await fetchHander(`${origin}/recordCorrect`, headers)
        return JSON.parse(data)
    },
    hitWriteAllMonthDataFetch: async (
        loginUser: any,
        selectedDate: any,
        recName: string,
        hitArrow: any,
        allArrow: any
    ) => {
        const year = moment(selectedDate).format("YYYY")
        const month = moment(selectedDate).format("M")
        const day = moment(selectedDate).format("D")
        const date = year + "/" + month + "/" + day

        headers.body = JSON.stringify({
            rec_name: recName,
            id: loginUser.id,
            team: loginUser.team,
            hit_arrow: hitArrow,
            all_arrow: allArrow,
            date,
        })
        const data = await fetchHander(`${origin}/hitWriteAllMonthDataFetch`, headers)
        return JSON.parse(data)
    },
    recordDelete: async (loginUser: any, record: any, recName: string) => {
        headers.body = JSON.stringify({
            deleteRecord: record,
            team: loginUser.team,
            id: loginUser.id,
            rec_name: recName,
        })
        const data = await fetchHander(`${origin}/recordDelete`, headers)
        return JSON.parse(data)
    },
}

export const memberRecordsRepository = {
    allMemberRecordsFetch: async (loginUser: any, memberData: any) => {
        headers.body = JSON.stringify({ memberData, id: loginUser.id, team: loginUser.team })
        const data = await fetchHander(`${origin}/allMemberRecordsFetch`, headers)
        return JSON.parse(data)
    },
}

export const attendanceRepository = {
    attendanceDataFetch: async (loginUser: any, memberData: any) => {
        headers.body = JSON.stringify({
            id: loginUser.id,
            rec_name: "attendance",
            memberData,
            team: loginUser.team,
        })
        const data = await fetchHander(`${origin}/attendanceDataFetch`, headers)
        return JSON.parse(data)
    },
    attendanceWrite: async (selectedDate: any, attendance: any, loginUser: any) => {
        const year = moment(selectedDate).format("YYYY")
        const month = moment(selectedDate).format("M")
        const day = moment(selectedDate).format("D")
        const date = year + "/" + month + "/" + day
        let attendanceData = {
            date,
            select: attendance.select,
            sentence: attendance.sentence,
            post_date: attendance.post_date,
            contributor: attendance.contributor,
            contributor_id: attendance.contributor_id,
            contributor_gender: attendance.contributor_gender,
            attendance_id: Date.now() + shortid.generate(),
        }
        headers.body = JSON.stringify({
            id: loginUser.id,
            attendanceData,
            team: loginUser.team,
        })
        const data = await fetchHander(`${origin}/attendanceWrite`, headers)
        return JSON.parse(data)
    },
    attendanceDelete: async (loginUser: any, attendance: any) => {
        headers.body = JSON.stringify({ id: loginUser.id, attendance, team: loginUser.team })
        const data = await fetchHander(`${origin}/attendanceDelete`, headers)
        return JSON.parse(data)
    },
    attendancePushMail: async (
        selectedDate: any,
        attendance: any,
        loginUser: any,
        memberData: any
    ) => {
        const year = moment(selectedDate).format("YYYY")
        const month = moment(selectedDate).format("M")
        const day = moment(selectedDate).format("D")
        const date = year + "/" + month + "/" + day
        let maillist = memberData.reduce((r: any, c: any) => {
            let result = r
            if (!(c.email === loginUser.email) && c.type == "leaders" && c.permission === "2") {
                result = [...r, c.email]
            }
            return result
        }, [])
        let attendanceData = {
            date,
            select: attendance.select,
            sentence: attendance.sentence,
            item: attendance.item,
            post_date: attendance.post_date,
            contributor: attendance.contributor,
        }
        headers.body = JSON.stringify({ attendanceData, maillist })
        const data = await fetchHander(`${origin}/attendancePushMail`, headers)
        return JSON.parse(data)
    },
}

export const contactRepository = {
    contactsFetch: async (loginUser: any) => {
        headers.body = JSON.stringify({ id: loginUser.id, team: loginUser.team })
        const data = await fetchHander(`${origin}/contactsFetch`, headers)
        return JSON.parse(data)
    },
    contactWrite: async (loginUser: any, contact: any, imageUrl?: string) => {
        let contactData = {
            title: contact.title,
            item: contact.item,
            itemColor: contact.itemColor,
            description: contact.description,
            post_date: contact.post_date,
            contributor: contact.contributor,
            contributor_id: contact.contributor_id,
            contributor_gender: contact.contributor_gender,
            contact_id: Date.now() + shortid.generate(),
            image_url: imageUrl,
        }
        headers.body = JSON.stringify({ id: loginUser.id, contactData, team: loginUser.team })
        const data = await fetchHander(`${origin}/contactWrite`, headers)
        return JSON.parse(data)
    },
    contactPushMail: async (loginUser: any, contact: any, memberData: any) => {
        let maillist = memberData.reduce((r: any, c: any) => {
            let result = r
            if (!(c.email === loginUser.email) && c.permission == "2" && c.type !== "ob") {
                result = [...r, c.email]
            }
            return result
        }, [])
        let contactData = {
            title: contact.title,
            item: contact.item,
            description: contact.description,
            contributor: contact.contributor,
        }
        headers.body = JSON.stringify({ contactData, maillist })
        const data = await fetchHander(`${origin}/contactPushMail`, headers)
        return JSON.parse(data)
    },
    contactDelete: async (loginUser: any, contactId: any) => {
        headers.body = JSON.stringify({ id: loginUser.id, contactId, team: loginUser.team })
        const data = await fetchHander(`${origin}/contactDelete`, headers)
        return JSON.parse(data)
    },
    contactResponse: async (loginUser: any, contactId: any) => {
        headers.body = JSON.stringify({
            id: loginUser.id,
            name: loginUser.name,
            team: loginUser.team,
            contactId,
        })

        const data = await fetchHander(`${origin}/contactResponse`, headers)
        return JSON.parse(data)
    },
}

export const passwordRepository = {
    userCheck: async (registerData: any) => {
        headers.body = JSON.stringify({
            name: registerData.lastName + registerData.firstName,
            email: registerData.email,
        })
        const data = await fetchHander(`${origin}/userCheck`, headers)
        return JSON.parse(data)
    },
    confirmCodeSend: async (registerData: any) => {
        headers.body = JSON.stringify({
            email: registerData.email,
        })
        const data = await fetchHander(`${origin}/confirmCodeSend`, headers)
        return JSON.parse(data)
    },
    passwordChange: async (registerData: any, userData: any) => {
        headers.body = JSON.stringify({
            id: userData.id,
            team: userData.team,
            password: registerData.password,
        })
        const data = await fetchHander(`${origin}/passwordChange`, headers)
        return JSON.parse(data)
    },
}

export const registerRepository = {
    teamFetch: async () => {
        const data = await fetchHander(`${origin}/teamFetch`)
        return JSON.parse(data)
    },
    userEmailCheck: async (registerData: any) => {
        headers.body = JSON.stringify({
            email: registerData.email,
        })
        const data = await fetchHander(`${origin}/userEmailCheck`, headers)
        return JSON.parse(data)
    },
    register: async (registerData: any) => {
        headers.body = JSON.stringify({
            name: registerData.lastName + registerData.firstName,
            email: registerData.email,
            password: registerData.password,
            gender: registerData.gender,
            belong: registerData.belong,
            teamId: registerData.teamId,
            teamName: registerData.teamName,
            type: registerData.type,
            rank: registerData.rank,
        })
        const data = await fetchHander(`${origin}/register`, headers)
        return JSON.parse(data)
    },
    registerMailSend: async (registerData: any) => {
        headers.body = JSON.stringify({
            email: registerData.email,
            belong: registerData.belong,
            teamName: registerData.teamName,
        })
        const data = await fetchHander(`${origin}/registerMailSend`, headers)
        return JSON.parse(data)
    },
}

export const registerCompleteRepository = {
    registerWrite: async (urlParamStr: any) => {
        headers.body = JSON.stringify({
            encrypted: urlParamStr,
        })
        const data = await fetchHander(`${origin}/registerWrite`, headers)
        return JSON.parse(data)
    },
    registerCompleteMailSend: async (userInfo: any) => {
        headers.body = JSON.stringify({
            email: userInfo.email,
        })
        const data = await fetchHander(`${origin}/registerCompleteMailSend`, headers)
        return JSON.parse(data)
    },
    registerLeadersMailSend: async (userInfo: any) => {
        headers.body = JSON.stringify({ userInfo })
        const data = await fetchHander(`${origin}/registerLeadersMailSend`, headers)
        return JSON.parse(data)
    },
}
